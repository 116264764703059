.sh_header_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.sh_header_container-first {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.sh_h1 {
    width: 45%;
    font-family: "Montserrat", sans-serif;
    font-size: 40px;
    letter-spacing: 2px;
    color: black;
    border-bottom: 2px solid white;
    padding-bottom: 2%;
    margin-bottom: 2%;
}

.sh_h1-black {
    width: 45%;
    font-family: "Montserrat", sans-serif;
    font-size: 40px;
    letter-spacing: 2px;
    color: black;
    border-bottom: 2px solid black;
    padding-bottom: 2%;
    margin-bottom: 2%;
}

.sh_h1-black-first {
    width: 45%;
    font-family: "Montserrat", sans-serif;
    font-size: 40px;
    letter-spacing: 2px;
    color: black;
    border-bottom: 2px solid black;
    padding-bottom: 2%;
    margin-bottom: 2%;
}

.sh_dload_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
}

.sh_dload_container-top {
    display: none;
}

.sh_dload_p {
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    color: rgb(88, 21, 143);
}
/* ############## Responsive ############### */

@media screen and (max-width: 900px) {
    .sh_header_container {
        flex-direction: column;
        justify-content: center;
    }
    .sh_header_container-first {
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
    .sh_h1,
    .sh_h1-black,
    .sh_h1-black-first {
        width: 80%;
        text-align: center;
        font-size: 30px;
    }
    .sh_dload_container {
        display: none;
    }
    .sh_dload_container-top {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 5%;
        opacity: 1;
    }
}
