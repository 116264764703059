.cntnt_container-pre {
    display: flex;
    flex-direction: column;
    background-color: white;
}

.cntnt_container-during {
    display: flex;
    flex-direction: column;
    background-color: rgba(238, 202, 216, 0.3);
}

.cntnt_container-basic {
    display: flex;
    flex-direction: column;
    background-color: white;
}

.cntnt_container-post {
    display: flex;
    flex-direction: column;
    background-color: rgba(146, 182, 194, 0.3);
}

.cntnt_container-positive {
    display: flex;
    flex-direction: column;
    background-color: rgba(238, 202, 216, 0.3);
}

.cntnt_content {
    display: flex;
    flex-direction: column;
    margin-top: 5%;
    margin-left: 5%;
    margin-right: 5%;
}

.cntnt_p {
    width: 45%;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    color: black;
    margin-bottom: 5%;
}

/* ############# RESPONSIVE ############## */

@media screen and (max-width: 900px) {
    .cntnt_header_container {
        width: 70%;
        margin-bottom: 0;
    }
    .cntnt_h1 {
        font-size: 35px;
    }
    .cntnt_p {
        width: 70%;
        align-self: center;
        font-size: 14px;
        text-align: center;
    }
}

@media screen and (max-width: 500px) {
    .cntnt_header_container {
        width: 85%;
    }
    .cntnt_p {
        width: 85%;
    }
}
