.res_container {
    height: calc(80vh - 90px);
    background-image: url("../../assets/resources_background.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.res_content_container {
    width: 40%;
    margin-left: 5%;
}

.res_h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 40px;
    letter-spacing: 2px;
    color: white;
}
.res_h3 {
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    color: black;
}

.res_p {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    margin-top: 3%;
    margin-bottom: 5%;
    color: white;
}

.res_banner {
    width: 100%;
    padding-bottom: 5%;
    margin-bottom: -4px;
    background-color: white;
    border: none;
}
/* Float four columns side by side */
.column {
    float: left;
    width: 30%;
    padding: 0 20px;
  }
  
  /* Remove extra left and right margins, due to padding */
  .row { 
    margin: 80px 20px 80px 0px;}
  
  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  
  /* Style the counter cards */
  .card-1 {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    padding: 20px;
    text-align: center;
    background-color: #D7E2E5;
    margin-right: 20px;
  }
  .card-2 {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    padding: 20px;
    text-align: center;
    background-color: #F3E8EC;
    margin-right: 20px;
  }
  .card-3{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    padding: 20px;
    text-align: center;
    background-color: #CDE9CD;
    margin-right: 20px;
  }
/* ############## Responsive ############### */

@media screen and (max-width: 900px) {
    .res_container {
        height: 40vh;
    }
    .res_content_container {
        width: 80%;
        margin-top: 5%;
    }
    .res_h1 {
        font-size: 30px;
        color: white;
    }
    .res_p {
        font-size: 14px;
        color: white;
    }
}
@media screen and (max-width: 600px) {
    .column {
      width: 100%;
      display: block;
      margin-bottom: 20px;
    }
  }
