.videos_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 5%;
}

.video_content,
.video_content-right,
.video_content-novideo {
    width: 46%;
}

.iframe_container {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3%;
}

.iframe_container-exists {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3%;
}

.video {
    position: absolute;
    height: 100%;
    width: 100%;
    border: none;
}

.description_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.description_content {
    width: 80%;
}

.video_header {
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    color: black;
    margin-bottom: 2%;
}

.video_description {
    width: 100%;
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    color: black;
}

.download_container {
    width: 20%;
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.icon-novideo {
    display: none;
}

/* ############## Responsive ############### */

@media screen and (max-width: 900px) {
    .videos_container {
        flex-direction: column;
        margin-bottom: 3%;
    }
    .video_content,
    .video_content-right {
        width: 100%;
    }
    .video_content-novideo {
        display: none;
    }
    .iframe_container-exists {
        margin-top: 5%;
    }
    .description_container {
        margin-bottom: 3%;
    }
    .video_description {
        font-size: 14px;
    }
}
