nav {
    background-color: white;
    height: 90px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 3%;
    padding-right: 3%;
}

.nav_logo {
    width: 180px;
    cursor: pointer;
}

.nav_logo_2 {
    width: 180px;
    border-left: 1px solid black;
    margin-left: 30px;
    margin-top: 5px;
    padding-left: 20px;
}

.nav_right_container_newuser {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.nav_right_container {
    width: 20%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.links_container_newuser {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.links_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.nav_p {
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    letter-spacing: 1px;
    color: black;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}

.nav_p:hover {
    color: #663873;
}

.nav_burger {
    display: none;
}

/* ############## Responsive ############### */

@media screen and (max-width: 1200px) {
    .nav_right_container {
        width: 225px;
    }
}

@media screen and (max-width: 1020px) {
    .links_container_newuser,
    .links_container,
    .tgl_container {
        opacity: 0.8;
    }
}

@media screen and (max-width: 1015px) {
    .links_container_newuser,
    .links_container,
    .tgl_container {
        opacity: 0.6;
    }
}

@media screen and (max-width: 1010px) {
    .links_container_newuser,
    .links_container,
    .tgl_container {
        opacity: 0.4;
    }
}

@media screen and (max-width: 1005px) {
    .links_container_newuser,
    .links_container,
    .tgl_container {
        opacity: 0.2;
    }
}

@media screen and (max-width: 995px) {
    .links_container_newuser,
    .links_container,
    .tgl_container {
        display: none;
    }

    .nav_right_container_newuser {
        justify-content: flex-end;
    }

    .nav_right_container {
        justify-content: flex-end;
    }

    .nav_burger {
        display: block;
        align-self: flex-end;
        opacity: 0.2;
    }
}

@media screen and (max-width: 990px) {
    .nav_burger {
        opacity: 0.4;
    }
}

@media screen and (max-width: 985px) {
    .nav_burger {
        opacity: 0.6;
    }
}

@media screen and (max-width: 980px) {
    .nav_burger {
        opacity: 0.8;
    }
}

@media screen and (max-width: 975px) {
    .nav_right_container {
        width: 50px;
    }
    .nav_burger {
        opacity: 1;
    }
}

@media screen and (max-width: 500px) {
    .nav_logo {
        width: 90px;
    }
    .nav_logo_2 {
        width: 120px;
    }
}
