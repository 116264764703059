.lt_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding-top: 1%;
    margin-bottom: -3%;
}

.lt_container-expanded {
    width: 100%;
    height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding-top: 1%;
    margin-bottom: -3%;
}

.lt_languages {
    width: 320px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.lt_p {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    color: black;
    padding-top: 0.5%;
    padding-bottom: 5px;
}

.lt_p-choice {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    color: black;
    padding-bottom: 5px;
}

.lt_p-choice:hover {
    border-bottom: 2px solid rgb(88, 21, 143);
    padding-bottom: 0;
    cursor: pointer;
}

/* ############## Responsive ############### */

@media screen and (max-width: 900px) {
    .lt_container,
    .lt_container-expanded {
        border-top: 1px solid black;
        border-bottom: 1px solid black;
        margin-bottom: 0;
        padding-top: 5%;
        padding-bottom: 5%;
    }
}
