.hiv_container {
    height: calc(80vh - 90px);
    background-image: url("../../assets/hiv-content_background.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.hiv_content_container {
    width: 40%;
    margin-left: 5%;
}

.hiv_h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 40px;
    letter-spacing: 2px;
    color: white;
}

.hiv_p {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    margin-top: 3%;
    margin-bottom: 5%;
    color: white;
}

.hiv_banner {
    width: 100%;
    padding-bottom: 5%;
    margin-bottom: -4px;
    background-color: white;
    border: none;
}

/* ############## Responsive ############### */

@media screen and (max-width: 900px) {
    .hiv_container {
        height: 40vh;
    }
    .hiv_content_container {
        width: 80%;
        margin-top: 5%;
    }
    .hiv_h1 {
        font-size: 30px;
        color: white;
    }
    .hiv_p {
        font-size: 14px;
        color: white;
    }
}
