.menu_container {
    height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
    background-color: white;
}

.menu_p {
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    letter-spacing: 2px;
    margin: 20px;
    color: black;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}

.menu_p:hover {
    color: #663873;
}
