/* ########## GLOBAL STYLES ########### */
.app_container {
    height: 100%;
    width: 100%;
    background-color: #f5f5f5;
    overflow-x: hidden;
}

a {
    text-decoration: none;
    margin: 0;
    padding: 0;
}
