.hf_container {
    height: calc(100vh - 90px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    overflow: hidden;
    background-color: rgba(126, 197, 184, 0.8);
}

.hf_form_button_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.hf_button {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 15px;
    width: 15%;
    height: 70px;
    border: none;
    border-radius: 6px;
    align-self: flex-end;
    margin-left: 2%;
    cursor: pointer;
    color: white;
    background-color: rgb(238, 202, 216);
    transition: 0.2s ease-in-out;
}

.hf_button:hover {
    transform: scale(1.05);
}

.hf_content_container {
    width: 55%;
    padding-left: 8%;
}

.hf_h2 {
    width: 135%;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    letter-spacing: 2px;
    margin-top: 2%;
    margin-bottom: 2%;
    color: white;
}

.hf_inputs_0,
.hf_inputs_1,
.hf_inputs_2 {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.hf_inputs_3 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.hf_personal_info_inputs {
    width: 47%;
    height: 40px;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    padding-left: 10px;
    margin-bottom: 2%;
    border: none;
    border-radius: 5px;
    color: grey;
    background-color: white;
}

.hf_personal_info_inputs-error {
    width: 47%;
    height: 40px;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    padding-left: 10px;
    margin-bottom: 2%;
    border: 1.5px solid red;
    border-radius: 5px;
    color: grey;
    background-color: white;
}

.hf_error_p {
    width: 100%;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-weight: 500;
    color: red;
    margin-bottom: 2%;
}

.hf_personal_info_inputs-full {
    width: 100%;
    height: 40px;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    padding-left: 10px;
    margin-bottom: 2%;
    border: none;
    border-radius: 5px;
    color: grey;
    background-color: white;
}

.hf_personal_info_inputs-lang {
    width: 48.5%;
    height: 40px;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    color: grey;
    background-color: white;
    padding-left: 10px;
    margin-bottom: 2%;
    border: none;
    border-radius: 5px;
}

.hf_personal_info_inputs-contentuse {
    width: 98.5%;
    height: 40px;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    padding-left: 10px;
    border: none;
    border-radius: 5px;
    color: grey;
    background-color: white;
}

.hf_marketing_container,
.hf_policy_container {
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    margin-top: 2%;
}

.hf_poltoggle_container {
    width: 41%;
    height: 40px;
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    background-color: white;
}

.hf_poltoggle_container-error {
    border: 2px solid red;
    width: 41%;
    height: 40px;
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    background-color: white;
}

.hf_marktoggle_container {
    width: 41%;
    height: 40px;
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    background-color: white;
}

.toggle_left {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: white;
    cursor: pointer;
}

.toggle_left-selected {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: rgb(238, 202, 216);
    cursor: pointer;
}

.toggle_right {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: white;
    cursor: pointer;
}

.toggle_right-selected {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: black;
    cursor: pointer;
}

.toggle_p {
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    color: grey;
}

.toggle_p-selected {
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    color: white;
}

.hf_marketing_p,
.hf_policy_p {
    width: 59%;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    color: white;
    margin-left: 3%;
    align-self: center;
}

.hf_p {
    display: flex;
    flex: 1;
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    color: white;
    width: 55%;
    margin-left: 8%;
    margin-top: 2%;
    cursor: pointer;
    margin-bottom: 20px;
}

/* ########### RESPONSIVE ########### */

@media screen and (max-width: 900px) {
    .hf_container {
        overflow: scroll;
    }
    .hf_content_container {
        width: 70%;
        padding-left: 15%;
    }
    .hf_h2 {
        width: 100%;
        margin-top: 3%;
        margin-bottom: 3%;
    }
    .hf_inputs_0,
    .hf_inputs_1,
    .hf_inputs_2 {
        flex-direction: column;
    }
    .hf_personal_info_inputs,
    .hf_personal_info_inputs-full {
        width: 98%;
        height: 35px;
        margin-bottom: 3%;
    }
    .hf_personal_info_inputs-lang {
        width: 100%;
        height: 35px;
    }
    .hf_personal_info_inputs-error {
        width: 98%;
        height: 35px;
    }
    .hf_personal_info_inputs-contentuse {
        width: 98%;
        height: 35px;
        margin-top: 1%;
    }
    .hf_marketing_container,
    .hf_policy_container {
        margin-top: 3%;
    }
    .hf_form_button_container {
        flex-direction: column;
    }
    .hf_button {
        align-self: flex-start;
        width: 20%;
        padding: 0;
        margin-left: 15%;
        margin-top: 2%;
    }
    .hf_p {
        font-size: 14px;
        margin-left: 15%;
        margin-top: 3%;
    }
    .hf_error_p {
        display: none;
    }
}

@media screen and (max-width: 700px) {
    .hf_content_container {
        width: 90%;
        padding-left: 5%;
    }
    .hf_h2 {
        margin-top: 4%;
        margin-bottom: 4%;
    }
    .hf_policy_container {
        margin-top: 5%;
    }
    .hf_marketing_container {
        margin-top: 5%;
        margin-bottom: 3%;
    }
    .hf_button {
        width: 30%;
        margin-left: 5%;
    }
    .hf_p {
        margin-left: 5%;
        width: 90%;
    }
}

@media screen and (max-width: 500px) {
    .hf_h2 {
        margin-top: 5%;
        margin-bottom: 5%;
    }
    .hf_personal_info_inputs,
    .hf_personal_info_inputs-full,
    .hf_personal_info_inputs-lang {
        height: 50px;
        font-size: 14px;
        margin-bottom: 5%;
    }
    .hf_personal_info_inputs-contentuse {
        height: 50px;
        font-size: 14px;
        margin-top: 1%;
    }
    .hf_use_info_input {
        width: 95%;
        max-height: 35px;
        padding: 40px 10px;
        font-size: 14px;
    }
    .hf_marketing_p,
    .hf_policy_p {
        font-size: 12px;
    }
    .hf_button {
        margin-top: 4%;
    }
    .hf_p {
        margin-top: 5%;
    }
}
