/* ################# LANDING FORM ################# */

.lf_container {
    height: calc(100vh - 90px);
    background-image: url("../../assets/Landing_background.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.lf_content_container {
    width: 50%;
    margin: 5%;
}

.lf_h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 42px;
    letter-spacing: 2px;
    color: white;
}

.lf_p {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    margin-top: 3%;
    margin-bottom: 5%;
    color: white;
}

.lf_h2 {
    font-family: "Montserrat", sans-serif;
    font-size: 22px;
    letter-spacing: 2px;
    color: white;
}

.lf_btn_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.health_btn {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 15px;
    width: 47%;
    height: 70px;
    border: none;
    border-radius: 6px;
    margin-top: 3%;
    cursor: pointer;
    color: white;
    background-color: #66cccc;
    transition: 0.2s ease-in;
}

.public_btn {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 15px;
    width: 47%;
    height: 70px;
    border: none;
    border-radius: 6px;
    margin-top: 3%;
    cursor: pointer;
    color: white;
    background-color: #663873;
    transition: 0.2s ease-in;
}

.public_btn:hover {
    transform: scale(1.05);
}

.health_btn:hover {
    transform: scale(1.05);
}

.scroll_indicator {
    display: flex;
    align-self: center;
    animation: wobble 5s;
    animation-iteration-count: infinite;
}

@keyframes wobble {
    5% {
        transform: translateY(2px);
    }
    10% {
        transform: translateY(-4px);
    }
    15% {
        transform: translateY(4px);
    }
    20% {
        transform: translateY(-2px);
    }
    25% {
        transform: translateY(1px);
    }
}

/* ################# ABOUT (BELOW) ################# */

.abt_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: white;
}

.abt_iframe_container {
    position: relative;
    width: 90%;
    padding-bottom: 50.625%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5%;
}

.abt_iframe {
    position: absolute;
    height: 100%;
    width: 100%;
    border: none;
}

.abt_copy {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 5%;
}

.abt_copy-bottom {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.abt_p_top {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 37px;
}

.abt_p {
    font-family: "Montserrat", sans-serif;
    font-size: 24px;
}

.abt_button_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.abt_button {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 15px;
    width: 25%;
    height: 70px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    color: white;
    background-color: #663873;
    margin-top: 6%;
    margin-bottom: 6%;
    margin-left: 6%;
    margin-right: 1%;
}

.abt_button:hover {
    transform: scale(1.05);
    transition: 0.2s ease-in;
}

.abt_a {
    width: 25%;
    margin-top: 6%;
    margin-bottom: 6%;
    margin-right: 6%;
    margin-left: 1%;
}

.abt_button_readmore {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 15px;
    width: 100%;
    height: 70px;
    border: 2px solid #663873;
    background-color: white;
    border-radius: 6px;
    cursor: pointer;
    color: #663873;
}

.abt_button_readmore:hover {
    transform: scale(1.05);
    transition: 0.2s ease-in;
}

.abt_banner {
    width: 100%;
    margin-bottom: 5%;
}

/* ############# RESPONSIVE ############## */

@media screen and (max-width: 900px) {
    .lf_content_container {
        width: 90%;
    }
    .abt_p_top {
        font-size: 20px;
    }
    .abt_p {
        font-size: 16px;
    }
    .abt_button,
    .abt_a {
        width: 30%;
    }
}

@media screen and (max-width: 500px) {
    .lf_container {
        height: calc(100vh - 138px);
    }
    .abt_button,
    .abt_a {
        width: 40%;
    }
}
