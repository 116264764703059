.pf_container {
    height: calc(100vh - 90px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background-color: rgba(171, 129, 205, 0.8);
}

.pf_form_button_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.pf_buttons_container {
    width: 47%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 3%;
}

.pf_button {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 15px;
    width: 48%;
    height: 70px;
    border: none;
    border-radius: 6px;
    align-self: flex-end;
    cursor: pointer;
    color: white;
    background-color: rgba(238, 202, 216);
    transition: 0.2s ease-in-out;
}

.pf_button_skip {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 15px;
    width: 48%;
    height: 70px;
    border: none;
    border-radius: 6px;
    align-self: flex-start;
    cursor: pointer;
    color: white;
    background-color: black;
    transition: 0.2s ease-in-out;
}

.pf_button:hover {
    transform: scale(1.05);
}

.pf_button_skip:hover {
    transform: scale(1.05);
}

.pf_content_container {
    width: 55%;
    padding-top: 5%;
    padding-left: 8%;
}

.pf_h2 {
    font-family: "Montserrat", sans-serif;
    font-size: 25px;
    letter-spacing: 2px;
    margin-top: 3%;
    margin-bottom: 5%;
    color: white;
}

.pf_inputs_1 {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.pf_inputs_2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.pf_personal_info_inputs {
    width: 47%;
    height: 40px;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    padding-left: 10px;
    margin-bottom: 3%;
    border: none;
    border-radius: 5px;
    background-color: white;
    color: grey;
}

.pf_personal_info_inputs_bottom {
    width: 47%;
    height: 40px;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    padding-left: 10px;
    border: none;
    border-radius: 5px;
    color: grey;
    background-color: white;
}

.pf_personal_info_inputs_bottom-spec {
    width: 98%;
    height: 40px;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    padding-left: 10px;
    margin-bottom: 3%;
    border: none;
    border-radius: 5px;
    color: grey;
}

.pf_personal_info_inputs_bottom-spec-error {
    width: 98%;
    height: 40px;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    padding-left: 10px;
    margin-bottom: 3%;
    border: 1.5px solid red;
    border-radius: 5px;
    color: grey;
}

.pf_error {
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-weight: 500;
    color: crimson;
    margin-top: 0;
    margin-bottom: 3%;
}

.pf_policy_container {
    width: 98%;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    margin-top: 3%;
}

.toggle_container {
    width: 48%;
    height: 40px;
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    background-color: white;
}

.toggle_container-error {
    border: 2px solid red;
    width: 48%;
    height: 40px;
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    background-color: white;
}

.toggle_left {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: white;
    cursor: pointer;
}

.toggle_left-selected {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: rgb(238, 202, 216);
    cursor: pointer;
}

.toggle_right {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: white;
    cursor: pointer;
}

.toggle_right-selected {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: black;
    cursor: pointer;
}

.toggle_p {
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    color: grey;
}

.toggle_p-selected {
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    color: white;
}

.pf_policy_p {
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    color: white;
    margin-left: 3%;
}

.pf_p {
    display: flex;
    flex: 1;
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    color: white;
    width: 55%;
    margin-left: 8%;
    margin-top: 1.5%;
    cursor: pointer;
}

/* ########### RESPONSIVE ########### */

@media screen and (max-width: 1095px) {
    .pf_h1 {
        font-size: 35px;
    }
    .pf_h2 {
        font-size: 20px;
    }
}

@media screen and (max-width: 905px) {
    .pf_content_container {
        width: 70%;
        padding-left: 15%;
    }
    .pf_form_button_container {
        flex-direction: column;
    }
    .hf_h1 {
        font-size: 30px;
    }
    .hf_h2 {
        font-size: 18;
    }
    .pf_inputs_1 {
        flex-direction: column;
    }
    .pf_inputs_2 {
        width: 100%;
    }
    .pf_personal_info_inputs {
        width: 100%;
        height: 35px;
    }
    .pf_personal_info_inputs_bottom {
        width: 100%;
        height: 35px;
        margin-bottom: 3%;
    }
    .pf_personal_info_inputs_bottom-spec,
    .pf_personal_info_inputs_bottom-spec-error {
        width: 98%;
        height: 35px;
        margin-bottom: 3%;
    }
    .pf_error {
        margin-top: 0;
        margin-bottom: 3%;
    }
    .pf_policy_container {
        margin-top: 0;
        margin-bottom: 1%;
    }
    .pf_p {
        font-size: 14px;
        margin-left: 15%;
    }
}

@media screen and (max-width: 710px) {
    .pf_content_container {
        width: 90%;
        padding-left: 5%;
    }
    .pf_h1 {
        font-size: 25px;
    }
    .pf_h2 {
        font-size: 14px;
    }
    .pf_p {
        margin-left: 5%;
        width: 90%;
    }
}

@media screen and (max-width: 500px) {
    .pf_personal_info_inputs,
    .pf_personal_info_inputs_bottom-spec,
    .pf_personal_info_inputs_bottom-spec-error {
        height: 50px;
        margin-bottom: 5%;
        font-size: 14px;
    }
    .pf_personal_info_inputs_bottom {
        height: 50px;
        font-size: 14px;
    }
    .pf_buttons_container {
        width: 100%;
        margin-top: 7%;
    }
    .pf_policy_container {
        margin-top: 4%;
    }
    .pf_p {
        font-size: 16px;
        margin-top: 5%;
    }
}
