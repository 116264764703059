.nav_burger {
    display: none;
}

/* ############## Responsive ############### */

@media screen and (max-width: 995px) {
    .nav_burger {
        display: block;
        align-self: flex-end;
        opacity: 0.2;
    }
}

@media screen and (max-width: 990px) {
    .nav_burger {
        opacity: 0.4;
    }
}

@media screen and (max-width: 985px) {
    .nav_burger {
        opacity: 0.6;
    }
}

@media screen and (max-width: 980px) {
    .nav_burger {
        opacity: 0.8;
    }
}

@media screen and (max-width: 975px) {
    .nav_burger {
        opacity: 1;
    }
}
