/* unvisited link */
a:link {
    color: #EFCAD8;
  }
 
  /* mouse over link */
  a:hover {
    color: grey;
  }
.cnt_container {
    height: calc(100vh - 90px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: rgba(15, 112, 114, 0.5);
}

.cnt_form_button_container {
    display: flex;
    flex-direction: row;
}

.cnt_content_container {
    width: 55%;
    padding-top: 2%;
    padding-left: 8%;
}

.cnt_h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 40px;
    letter-spacing: 2px;
    color: white;
}

.cnt_h2 {
    font-family: "Montserrat", sans-serif;
    font-size: 25px;
    letter-spacing: 2px;
    margin-top: 2%;
    margin-bottom: 3%;
    color: white;
}

.cnt_h3 {
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    letter-spacing: 2px;
    margin-top: 2%;
    margin-bottom: 3%;
    color: white;
}

.cnt_inputs_0,
.cnt_inputs_1 {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.cnt_inputs_2 {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.cnt_info_inputs {
    width: 47%;
    height: 40px;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    padding-left: 10px;
    margin-bottom: 3%;
    border: none;
    border-radius: 5px;
    color: grey;
}

.cnt_info_inputs-error {
    width: 47%;
    height: 40px;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    padding-left: 10px;
    margin-bottom: 3%;
    border: 1.5px solid red;
    border-radius: 5px;
    color: grey;
}

.cnt_infomessage_inputs {
    resize: none;
    max-height: 20px;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    padding: 30px 10px;
    word-wrap: break-word;
    border: none;
    border-radius: 5px;
    color: grey;
}

.cnt_infomessage_inputs-error {
    resize: none;
    max-height: 20px;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    padding: 30px 10px;
    word-wrap: break-word;
    border: 1.5px solid red;
    border-radius: 5px;
    color: grey;
}

.cnt_error {
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-weight: 500;
    color: red;
    margin-top: 3%;
}

.cnt_newsletter_container {
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    margin-top: 3%;
}

.toggle_container {
    width: 48%;
    height: 40px;
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    background-color: white;
}

.toggle_left {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: white;
    cursor: pointer;
}

.toggle_left-selected {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: rgb(238, 202, 216);
    cursor: pointer;
}

.toggle_right {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: white;
    cursor: pointer;
}

.toggle_right-selected {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: black;
    cursor: pointer;
}

.toggle_p {
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    color: grey;
}

.toggle_p-selected {
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    color: white;
}

.cnt_p {
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    color: white;
    margin-left: 3%;
}

.cnt_button {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 15px;
    width: 15%;
    height: 70px;
    border: none;
    border-radius: 6px;
    align-self: flex-end;
    margin-left: 2%;
    cursor: pointer;
    color: white;
    background-color: rgb(238, 202, 216);
    transition: 0.2s ease-in-out;
}

.cnt_button:hover {
    transform: scale(1.05);
}

/* ########### RESPONSIVE ########### */

@media screen and (max-width: 1095px) {
    .cnt_h1 {
        font-size: 38px;
    }
    .cnt_h2 {
        font-size: 23px;
    }
}

@media screen and (max-width: 1015px) {
    .cnt_h1 {
        font-size: 35px;
    }
    .cnt_h2 {
        font-size: 20px;
    }
}

@media screen and (max-width: 905px) {
    .cnt_h1 {
        font-size: 32px;
    }
    .cnt_h2 {
        font-size: 18px;
    }
}

@media screen and (max-width: 900px) {
    .cnt_container {
        overflow: scroll;
    }
    .cnt_content_container {
        width: 58%;
    }
    .cnt_inputs_0,
    .cnt_inputs_1 {
        flex-direction: column;
    }
    .cnt_info_inputs,
    .cnt_info_inputs-error {
        width: calc(100% - 10px);
        height: 35px;
        margin-bottom: 3%;
    }
    .cnt_infomessage_inputs,
    .cnt_infomessage_inputs-error {
        max-height: 15px;
    }
    .cnt_form_button_container {
        flex-direction: column;
    }
    .cnt_newsletter_container {
        flex-direction: column;
        align-items: flex-start;
        height: 72px;
    }
    .cnt_p {
        margin-left: 0;
        margin-top: 3%;
    }
    .cnt_button {
        align-self: flex-start;
        width: 20%;
        padding: 0;
        margin-left: 8%;
        margin-top: 2%;
    }
}

@media screen and (max-width: 830px) {
    .cnt_content_container {
        padding-top: 4%;
        padding-left: 10%;
        width: 60%;
    }
    .cnt_button {
        margin-left: 10%;
    }
}

@media screen and (max-width: 800px) {
    .cnt_content_container {
        padding-left: 11%;
        width: 65%;
    }
    .cnt_button {
        margin-left: 11%;
    }
}

@media screen and (max-width: 750px) {
    .cnt_content_container {
        padding-left: 15%;
        width: 70%;
    }
    .cnt_button {
        margin-left: 15%;
    }
}

@media screen and (max-width: 650px) {
    .cnt_content_container {
        width: 80%;
        padding-left: 10%;
    }
    .cnt_h1 {
        font-size: 28px;
    }
    .cnt_h2 {
        font-size: 15;
    }
    .cnt_button {
        margin-left: 10%;
    }
}

@media screen and (max-width: 570px) {
    .cnt_h1 {
        font-size: 26px;
    }
    .cnt_h2 {
        font-size: 13px;
    }
}

@media screen and (max-width: 500px) {
    .cnt_content_container {
        width: 90%;
        padding-left: 5%;
    }
    .cnt_h1 {
        font-size: 25px;
    }
    .cnt_h2 {
        font-size: 12px;
    }
    .cnt_button {
        width: 30%;
        margin-left: 5%;
    }
}
