.ftr_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ftr_container-hiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
}

.ftr_social {
    width: 10%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.social_a {
    width: 25%;
    text-align: center;
    display: block;
}

.ftr_social_icon {
    width: 100%;
}

.ftr_copyright_container {
    width: 320px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-self: center;
    margin-bottom: 2%;
    margin-top: 2%;
}

.ftr_copyright_p {
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    margin-left: 5px;
}

/* ########### RESPONSIVE ########### */

@media screen and (max-width: 1230px) {
    .ftr_copyright_container {
        width: 320px;
    }
}

@media screen and (max-width: 1080px) {
    .ftr_social {
        width: 110px;
    }
}
